import Vue from 'vue'
import { ToastPlugin, ModalPlugin, IconsPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import router from './router'
import store from './store'
import App from './App.vue'
import * as XLSX from './libs/xlsx.mini.min.js'
import { io } from "socket.io-client";
import BCustomSpinner from '@core/components/BCustomSpinner.vue'
import cronstrue from 'cronstrue/i18n';
import { BaklavaVuePlugin } from "@baklavajs/plugin-renderer-vue";
class EventEmitter {
  constructor() {
    this.callbacks = {}
  }

  on(event, cb) {
    if (!this.callbacks[event]) this.callbacks[event] = [];
    this.callbacks[event].push(cb)
  }

  emit(event, data) {
    let cbs = this.callbacks[event]
    if (cbs) {
      cbs.forEach(cb => cb(data))
    }
  }
}
window.EventEmitter = EventEmitter;
globalThis.qm_status_watcher = new EventEmitter();
globalThis.cronstrue = cronstrue;
globalThis.register_info = {};
globalThis.permissions = {};
globalThis.user_agent = null;
globalThis.XLSX = XLSX.default;
globalThis._voyce = {};
globalThis.username = '';
globalThis.qm_token = '';
globalThis.token = 'DkvxS+o6BWfN6jrSZ1Sy9dXom9tLNydPgM1GRABGoGpzG4khG9oJPZx40v3YKoCFQVmnDzY2xF+6JuNwc7WP8XHdE/HU4KlJN0ntkosL8POzKuKa6SNql2VqKkNjvKN3pL+bIkIxVVHmSGWOb0eAoap8vKr0yTbzmWN3sP77/l5BMzbREqSFH7nBzBbA1PIr9NzgK7B9O+BUpVwt+EbcDpDK3VVOYDDXYy23PgGX33+OY5b8Dg/WaeYQuuzWvj2LlyJNNUEa0jmE1dnIHGY+gQYdUZUcQS0ALGGzgCz5Gf5tMjdkcXd+pJ39ueNG7ziiP7IDh3ooKV+wrHKzkv7E+pswp4p2miAEtPXVqkjX50IkkSTG3FEIHM6F5npbV2OPPD3Z6LzO62qTWJD+dDf4LhklqUCp+rm/3pPSu8WiXQMXTQ9zlXqBt0K6a2pu/Db5XDVbBn/ZNms1zAtfNThz/RyJk1nZItMnBRGc9SupILmV+HhqkdqPKc2Ef7qMVEzs5LScTlbS64k1jxNXHYmZutS50ptcflcLmeLEXi+Ngt5yGzB7Fyar4lXr9SmiupDVU2lIlpBGQp1JbB0de2j8P5LAAe7qHded4xtX8SLvKVAt5q+b8l308yNIpMqrz/Ps6W+ktjbVPHs6903jB6xEoHs0UUEigsQgDBIAELxl8qrwU2yJc86iAteNPE2Tx1mCDurDd9J7l8kBui1RwIFtiDoqWqkXGxM2sWA+R/tsT4X2ZrbND6v6nzbDOptM0QjxdWzp8wDegUabDPlL/gNSvm7SsjQgvUyx34oJpdiMe2rnrIlUFlVsZthAY8DRsK3LlaM7PpBTMNJ3F3t9nk/uUZ8GwkrEy7lJEUwcbdl1MYL471Hd';
globalThis.uuid = '7ae6a173-7646-4392-89fc-afc8128d39a4';
globalThis.projects = [];
globalThis.queues = [];
globalThis.user = {};
globalThis.crm = null;
globalThis.static_user = false;
globalThis.maskPhoneNumber = function maskPhoneNumber(phoneNumber) {
  var maskedNumber = phoneNumber.slice(0, -2).replace(/./g, '*') + phoneNumber.slice(-2);
  return maskedNumber;
}
globalThis.script = `<center>
<div>
<button class="btn" onclick="speedDown()"
            type="button">
      <<
    </button>
<button class="btn" onclick="speedNormal()"
            type="button">
      Normal
    </button>
    <button class="btn" onclick="speedUp()"
            type="button">
      >>
    </button>
    </div>
    <h5 style="margin-top:10px; font-family:sans-serif,Arial" id="a_rate">Oynatma Hızı: 1.0x</h5>
</center>
<script>
  var a = document.getElementById("voyce_audio");
  var span = document.getElementById("a_rate");
 
 function speedUp() {
     a.playbackRate += 0.25;
     span.innerHTML = a.playbackRate + 'x';
 }
 function speedNormal() {
     a.playbackRate = 1.0;
     span.innerHTML = a.playbackRate + 'x';
 }

 function speedDown() {
  a.playbackRate -= 0.25;
  span.innerHTML = a.playbackRate + 'x';
 }
</script>`;

Vue.prototype.$ws = io;
Vue.prototype.$xlsx = XLSX.default;

globalThis.socketio = io;
// Global Components
import './global-components'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
// import '@fullcalendar/'
import '@fullcalendar/core/vdom'
import '@/libs/clipboard'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(IconsPlugin)
Vue.use(BaklavaVuePlugin);

// Composition API
Vue.component("b-custom-spinner", BCustomSpinner);
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false




import { lang, _l, selectLang, access } from '@/lang/lang.js';



globalThis.selected_lang = 'en';
globalThis.lang = lang;
globalThis._l = _l;
globalThis.selectLang = selectLang;
globalThis._lang = _l(window.localStorage.getItem('language') ?? "tr");
globalThis.app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

globalThis.Vue = Vue;
